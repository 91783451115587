import React from "react";
import _ from "lodash";
import classnames from "classnames";
import CountUp from "react-countup";
import styles from "./index.module.scss";
import AppContainer from "../AppContainer";
import Loader from "../Loader";
import Button from "../Button";
import List from "../List";
import UserAvatar from "../UserAvatar";
import { getMemeberSinceDate } from "../../utils/user";
import { getAppMediaSrc, mediaTypes } from "../../utils/media";

export default ({
  T,
  appStyles,
  user,
  order,
  onError,
  onRecharge,
  onGoBack,
  hasChargeCard,
  currencySymbol: appCurrencySymbol,
  cashbackDiscounts,
  hasCashback,
  hasPointBank,
  classNames,
  isCardRecentlyCharged,
}) => {
  const { loyaltyProfile = {} } = user;
  const cardBackgroundImageSrc = getAppMediaSrc(
    mediaTypes.cardBackgroundImage,
    appStyles,
  );

  const openChargeCardInstance = _.get(
    loyaltyProfile,
    "data.openChargeCardInstance",
  );

  const amountOnCard = openChargeCardInstance
    ? openChargeCardInstance.loadedAmount - openChargeCardInstance.usedAmount
    : "";

  const rewardPointBalance = _.get(
    loyaltyProfile,
    "data.rewardPointBalance.balance",
  );

  const visitCount = _.get(loyaltyProfile, "data.visitCount");

  const currencySymbol = appStyles.pointsRewards ? "" : appCurrencySymbol || "";

  const username = _.get(user, "userDetails.data.name");

  return (
    <div className={classNames}>
      <div style={{ display: "flex", flexDirection: "column", margin: 16 }}>
        <span
          style={{
            color: appStyles.titleOnBackgroundColor,
            textAlign: "center",
            fontSize: "1.3rem",
          }}
        >
          {T("{{business_name}} Card")}
        </span>
        <small style={{ marginTop: 10, textAlign: "center" }}>
          {_.get(openChargeCardInstance, "chargeCardExplanation", "")}
        </small>
        {isCardRecentlyCharged && (
          <div
            style={{
              fontSize: "1.2rem",
              margin: "16px 0",
              textAlign: "center",
              color: appStyles.titleOnBackgroundColor,
            }}
          >
            {T("Your card was loaded successfully!")}
          </div>
        )}
      </div>
      <div style={{ margin: 16 }}>
        <div
          className={styles.ChargeCard}
          style={
            cardBackgroundImageSrc
              ? {
                  background: `url(${cardBackgroundImageSrc})`,
                  textShadow: "0 0 8px #333",
                }
              : {}
          }
        >
          <div
            className={classnames(
              styles.CardHeader,
              appStyles.rtl && styles.RTL,
            )}
          >
            <div className={styles.Title}>
              <UserAvatar
                size={20}
                name={username}
                avatarUrl={_.get(user, "userDetails.data.avatarImage.url")}
                appStyles={appStyles}
                style={appStyles.rtl ? { marginLeft: 16 } : { marginRight: 16 }}
              />
              {username}
            </div>
            <div className={styles.MemebrSince}>
              {getMemeberSinceDate(user, T)}
            </div>
          </div>

          {loyaltyProfile.loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Loader appStyles={appStyles} />
              <strong>{T("Loading Your Card...")}</strong>
            </div>
          ) : (
            <div>
              {loyaltyProfile.error ? (
                <div>
                  <h4
                    style={{
                      color: appStyles.accentColor,
                      textAlign: "center",
                    }}
                  >
                    {T("Connection Error :(")}
                  </h4>
                  <Button
                    linkStyle
                    centered
                    slim
                    appStyles={appStyles}
                    onClick={onError}
                    style={{ margin: 20 }}
                  >
                    {T("Try Again")}
                  </Button>
                </div>
              ) : (
                loyaltyProfile.data && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {(hasCashback || hasChargeCard) &&
                    _.isNumber(amountOnCard) ? (
                      <div
                        className={classnames(
                          styles.StatsElement,
                          styles.CashbackCredit,
                        )}
                      >
                        <div className={styles.StatValue}>
                          <div className={styles.CurrencySymbol}>
                            {appCurrencySymbol}
                          </div>
                          <div>
                            {amountOnCard ? (
                              isCardRecentlyCharged ? (
                                <CountUp
                                  end={amountOnCard}
                                  decimals={2}
                                  duration={1}
                                />
                              ) : (
                                amountOnCard.toFixed(2)
                              )
                            ) : (
                              0
                            )}
                          </div>
                        </div>
                        <div className={styles.StatLabel}>{T("Credit")}</div>
                      </div>
                    ) : (
                      !appStyles.hideVisitCount &&
                      _.isNumber(visitCount) && (
                        <div className={classnames(styles.StatsElement)}>
                          <div className={styles.StatValue}>
                            <div>{visitCount}</div>
                          </div>
                          <div className={styles.StatLabel}>{T("Visits")}</div>
                        </div>
                      )
                    )}

                    {hasPointBank && _.isNumber(rewardPointBalance) && (
                      <div className={classnames(styles.StatsElement)}>
                        <div className={styles.StatValue}>
                          <div>{rewardPointBalance}</div>
                        </div>
                        <div className={styles.StatLabel}>{T("Points")}</div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </div>
        {hasCashback &&
          !_.isEmpty(cashbackDiscounts) &&
          _.map(cashbackDiscounts, (cashbackDiscount) => {
            return (
              <div
                style={{ margin: "0 10px 0 10px" }}
                key={cashbackDiscount.policyId}
              >
                <span
                  style={{
                    color: appStyles.titleOnBackgroundColor,
                  }}
                >
                  {T(
                    `We give you ${appCurrencySymbol}${
                      cashbackDiscount.cashbackAmount
                    } for every ${currencySymbol}${
                      cashbackDiscount.amountToSpendForCashback
                    } you spend`,
                  )}
                </span>

                <div className={styles.RemainderBar}>
                  <div
                    className={styles.FillBar}
                    style={{
                      background: appStyles.accentColor,
                      width: `${cashbackDiscount.percentFilled}%`,
                    }}
                  />
                  {cashbackDiscount.percentFilled > 10 && (
                    <div className={styles.MoneyIndicatorWrapper}>
                      <div className={styles.MoneyIndicator}>{"|"}</div>
                      <div
                        className={styles.MoneyIndicator}
                      >{`${currencySymbol}0`}</div>
                    </div>
                  )}

                  <div
                    className={styles.MoneyIndicatorWrapper}
                    style={{
                      width: `${cashbackDiscount.percentFilled * 2}%`,
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.MoneyIndicator}>{"|"}</div>
                    <div
                      className={styles.MoneyIndicator}
                    >{`${currencySymbol}${cashbackDiscount.amountSpent.toFixed(
                      appStyles.pointsRewards ? 0 : 2,
                    )}`}</div>
                  </div>

                  {cashbackDiscount.percentFilled < 84 && (
                    <div
                      className={styles.MoneyIndicatorWrapper}
                      style={{
                        ...(appStyles.rtl ? { left: 0 } : { right: 0 }),
                        alignItems: "flex-end",
                      }}
                    >
                      <div className={styles.MoneyIndicator}>{"|"}</div>
                      <div
                        className={styles.MoneyIndicator}
                      >{`${currencySymbol}${
                        cashbackDiscount.amountToSpendForCashback
                      }`}</div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {hasChargeCard && (
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isCardRecentlyCharged && !_.isEmpty(order.orderItems) && (
              <Button appStyles={appStyles} centered onClick={onGoBack}>
                {T("Back to Checkout")}
              </Button>
            )}
            <Button
              style={{ marginTop: 20 }}
              linkStyle={isCardRecentlyCharged && !_.isEmpty(order.orderItems)}
              appStyles={appStyles}
              centered
              onClick={onRecharge}
            >
              {isCardRecentlyCharged
                ? T("Recharge Card Again")
                : T("Recharge Card")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
